.navbar-user {
    &_avatar{
        display: flex;
        justify-content: center;
        padding: 1.5rem;
        &_img{
            cursor: pointer;
            width: 64px !important;
            height: 64px !important;
        }
    }
    &_user{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 1.5rem;
    }
}